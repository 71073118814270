<template>
  <ws-dialog
      @save="addEditDocument"
      v-model="display"
      :title="newEntity ? $t('documentflow.document.add') : $t('documentflow.document.edit')"
      width="476"
      fill-height
      fullscreen-align="right"
      not-centered
  >

    <!-- CONTENT-->

    <!-- Document Name -->
    <ws-text-field
        v-model="entityData.name"
        :label="$t('Name')"
        :placeholder="$t('EnterName')"
        clearable
        :error="nameError"
        @input="nameError = null"
    />
    <!-- Structure Entity-->
    <ws-select
        @input="handleStructureChange"
        class="mt-6"
        v-model="entityData.structure_entity_id"
        :label="$t('StructureEntity')"
        :placeholder="$t('Choose')"
        :items="hrStructureEntitiesSelect"
        :error="structureError"
        :autocomplete="hrStructureEntitiesSelect.length > 7"
    />
    <!-- Document Type -->
    <ws-select
        @input="handleTypeChange"
        v-model="entityData.type"
        :label="$t('Type')"
        :placeholder="$t('Choose')"
        :items="documentTypesSelectFiltered"
        :error="typeError"
        :autocomplete="documentTypesSelectFiltered.length > 7"
        class="mt-5"

    />

    <!-- Document Code and Date  -->
    <v-row no-gutters class="mt-5 mb-7" >
      <v-col cols="6" class="pr-1">
        <ws-text-field
            v-model="entityData.code"
            :label="$t('Number')"
            :placeholder="$t('documentflow.document.enter_code')"
            clearable
            :error="codeError"
            @input="codeError = null"
        />
      </v-col>
      <v-col cols="6" class="pl-1">
        <ws-date-picker
            v-model="entityData.date"
            :label="$t('Date')"
            :placeholder="$t('ChooseDate')"
            time-stamp
            :error="dateError"
            @input="dateError = null"
        />
      </v-col>
    </v-row>

    <!-- Signators -->
    <div>
      <h5 :style="`color : ${wsACCENT}`" class="mb-3 font-weight-regular">{{ $t('Signators') }}</h5>
      <v-sheet   color="transparent"  :set="!user ? user=[] : null" :style="`border : 1px solid ${wsDARKLIGHT} !important`" class="wsRoundedLight">

        <v-sheet
            v-for="(signator,index) in entityData.signators"
            :key="signator.user_id"
            color="transparent"
            class="px-3"
            min-height="50"
            height="50"
            max-height="50"
            :class="[{'mt-2' : index === 0 }]"
        >
          <v-sheet
              :set="user[index] = getUser(signator.user_id)"
              class="d-flex justify-space-between align-center fill-height"
              style="width : 100%"
          >
            <div v-if="user && user[index]" class="d-flex align-center" >
              <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                <v-icon v-if="!user[index].img" dark>mdi-account</v-icon>
                <v-img v-else :src="user[index].img"></v-img>
              </v-avatar>
              <div>
                <h5 :style="`color : ${wsACCENT}`">{{ user[index].text }} </h5>
                <ft-select
                    :disabled="user[index].positions_select.length < 2"
                    v-model="entityData.signators[index].position_id"
                    :items="user[index].positions_select"
                    :key="index"
                >
                  <template #default="{text}">
                    <v-sheet color="transparent" class="d-flex justify-space-between align-center shorten-text">
                      <h5 class="font-weight-regular shorten-text">
                        {{ text || '' }}
                      </h5>
                      <v-icon v-if="user[index].positions_select.length > 1" :color="wsACCENT">mdi-menu-down</v-icon>
                    </v-sheet>
                  </template>

                </ft-select>
              </div>

            </div>
            <div class="d-flex align-center">

              <v-btn class="ml-5" @click="removeSignator(signator.user_id)" icon small>
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>

          </v-sheet>

        </v-sheet>
        <v-divider v-if="entityData.signators.length > 0" class="mt-3" :style="`border-color : ${wsDARKLIGHT}`" />
        <ws-select
            :key="updateTrigger"
            v-if="managersSelectFiltered.length > 0"
            @input="addSignator"
            :value="newSignator"
            :items="managersSelectFiltered"
            :placeholder="$t('documentflow.signature.choose_signator')"
            autocomplete
            :error="signatorError"
            flat solo
        >
          <template #item="{item}">

            <v-sheet  width="100%" max-width="400px" color="transparent" class="d-flex align-center" :style="`border-bottom : 1px solid ${wsBACKGROUND} !important`" height="46"  >


              <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                <v-icon v-if="!item.img" dark>mdi-account</v-icon>
                <v-img v-else :src="item.img"></v-img>
              </v-avatar>

              <div>
                <h5 :style="`color : ${wsACCENT}`">{{ item.text }} </h5>
                <div class="shorten-text">
                  <h5   class="d-flex align-center font-weight-regular shorten-text"  v-if="item.positions_select && item.positions_select.length > 0">
                    {{ item.positions_select[0].text }}
                  </h5>
                </div>
              </div>



            </v-sheet>

          </template>
        </ws-select>

      </v-sheet>
    </div>
    <!-- Aprovals  -->
    <div>
      <h5 :style="`color : ${wsACCENT}`" class="mt-5 mb-3 font-weight-regular">{{ $t('documentflow.document.has_approval_signator') }}</h5>
      <v-sheet   color="transparent"  :set="!apruser ? apruser=[] : null" :style="`border : 1px solid ${wsDARKLIGHT} !important`" class="wsRoundedLight">

        <v-sheet
            v-for="(signator,index) in entityData.aprovals"
            :key="signator.user_id"
            color="transparent"
            class="px-3"
            min-height="50"
            height="50"
            max-height="50"
            :class="[{'mt-2' : index === 0 }]"
        >
          <v-sheet
              :set="apruser[index] = getUser(signator.user_id , true)"
              class="d-flex justify-space-between align-center "
              style="width : 100%"
          >
            <div v-if="apruser && apruser[index]" class="d-flex align-center" >
              <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                <v-icon v-if="!apruser[index].img" dark>mdi-account</v-icon>
                <v-img v-else :src="apruser[index].img"></v-img>
              </v-avatar>
              <div>
                <h5 :style="`color : ${wsACCENT}`">{{ apruser[index].text }} </h5>
                <ft-select
                    :disabled="apruser[index].positions_select.length < 2"
                    v-model="entityData.aprovals[index].position_id"
                    :items="apruser[index].positions_select"
                    :key="index"
                >
                  <template #default="{text}">
                    <v-sheet color="transparent" class="d-flex justify-space-between align-center shorten-text">
                      <h5   class="font-weight-regular shorten-text">
                        {{ text || '' }}
                      </h5>
                      <v-icon v-if="apruser[index].positions_select.length > 1" :color="wsACCENT">mdi-menu-down</v-icon>
                    </v-sheet>

                  </template>

                </ft-select>
              </div>

            </div>
            <div class="d-flex align-center">

              <v-btn class="ml-5" @click="removeSignator(signator.user_id , true)" icon small>
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>

          </v-sheet>

        </v-sheet>
        <v-divider v-if="entityData.aprovals.length > 0" class="mt-3" :style="`border-color : ${wsDARKLIGHT}`" />
        <ws-select
            :key="updateTrigger"
            v-if="managersSelectFiltered.length > 0"
            @input="addSignator($event, true)"
            :value="newSignator"
            :items="managersSelectFiltered"
            :placeholder="$t('documentflow.signature.choose_signator')"
            autocomplete
            :error="signatorError"
            flat solo
        >
          <template #item="{item}">

            <v-sheet width="100%" max-width="400px" color="transparent" class="d-flex align-center" :style="`border-bottom : 1px solid ${wsBACKGROUND} !important`" height="46"  >
              <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                <v-icon v-if="!item.img" dark>mdi-account</v-icon>
                <v-img v-else :src="item.img"></v-img>
              </v-avatar>
              <div>
                <h5 :style="`color : ${wsACCENT}`">{{ item.text }} </h5>
                <div class="shorten-text">
                  <h5   class="d-flex align-center font-weight-regular shorten-text"  v-if="item.positions_select && item.positions_select.length > 0">
                    {{ item.positions_select[0].text }}
                  </h5>
                </div>
              </div>

            </v-sheet>

          </template>
        </ws-select>

      </v-sheet>

    </div>
    <!-- Lists  -->

    <ws-select
        v-model="entityData.contact_list_id"
        :label="$t('Newsletter')"
        :placeholder="$t('ChooseList')"
        :items="lists || []"
        class="mt-5"
        clearable
    />

    <!-- Is Child  -->
<!--    <ws-switch-->
<!--        v-model="entityData.is_child"-->
<!--        :placeholder="$t('documentflow.document.has_parent')"-->
<!--    />-->

<!--    <ws-text-field-->
<!--        v-if="entityData.is_child"-->
<!--        @change="checkParentDocument"-->
<!--        v-model="entityData.parent_id"-->
<!--        :label="$t('documentflow.document.parent.title')"-->
<!--        :placeholder="$t('documentflow.document.parent.placeholder')"-->
<!--    />-->

    <!-- Extra Protection -->
    <ws-switch
        v-model="entityData.has_extra_protection"
        :placeholder="$t('documentflow.document.has_extra_protection')"
    />

    <div class="mt-5 pb-3">
      <h5 class="mb-3 mt-5" :style="`color : ${wsACCENT}`">{{ $t('documentflow.document.sign_document') }}: </h5>
      <h4 class="mb-3 font-weight-regular" v-if="fileError" :style="`color : ${wsWARNING}`">{{ $t('ChooseFile') }}</h4>
      <ws-file-uploader
          v-if="!entityData.file"
          class="mt-5"
          @success="addFile"
          pdf
      />
      <v-sheet v-else class="d-flex justify-space-between align-center pa-3" :style="`border : 1px solid ${wsBACKGROUND}`" >
        <div class="d-flex align-center">
          <v-icon class="mr-2" :color="wsACCENT">mdi-file</v-icon>
          <h5 :style="`color : ${wsACCENT}`" class="shorten-text"> {{ entityData.file_name }}</h5>
        </div>
        <v-btn @click="deleteFile" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-sheet>

      <div v-if="entityData.file">
        <h5 class="mb-3 mt-5" :style="`color : ${wsACCENT}`">{{ $t('documentflow.document.original_document') }}: </h5>
        <ws-file-uploader
            v-if="!entityData.original_file"
            class="mt-5"
            @success="addFile($event , true)"
        />
        <v-sheet v-else class="d-flex justify-space-between align-center pa-3" :style="`border : 1px solid ${wsBACKGROUND}`" >
          <div class="d-flex align-center">
            <v-icon class="mr-2" :color="wsACCENT">mdi-file</v-icon>
            <h5 :style="`color : ${wsACCENT}`" class="shorten-text"> {{ entityData.original_file_name }}</h5>
          </div>
          <v-btn @click="deleteFile(true)" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-sheet>

      </div>

    </div>

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "DocumentSettingsDialog",
  props : {
    value : {
      type : Boolean
    },
    entity : {
      type : Object,
      default() {
        return {
          signators : [],
          aprovals : []
        }
      }
    },
    itemsSelectData : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      display : false,
      entityData : {
        signators : [],
        aprovals : []
      },

      documentTypesSelect : [],
      documentTypesData : {},
      documentDepartmentsSelect : [],
      hrStructureEntitiesSelect : [],
      managersSelect : [],
      newSignator : null,
      file : {},
      originalFile : {},
      updateTrigger : 0,
      lists : [],

      //errors
      nameError : false,
      typeError : false,
      codeError : false,
      dateError : false,
      signatorError : false,
      fileError : false,
    }
  },
  computed : {
    newEntity() {
      return this.entityData.uuid
    },
    documentTypesSelectFiltered() {
      let items = this.COPY(this.documentTypesSelect)

      if ( this.entityData.structure_entity_id ) {
        items = items.filter(el=> el.categories.includes(this.entityData.structure_entity_id))
      }

      return items
    },
    managersSelectFiltered() {
      let items = this.COPY(this.managersSelect)
      if ( !this.entityData.signators ) {
        return items
      }
      return items.filter(el => !(this.entityData.signators.map(el => el.user_id).includes(el.value) || this.entityData.aprovals.map(el => el.user_id).includes(el.value) )|| this.newSignator === el.value)
    },
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    itemsSelectData : {
      handler() {
        this.handleItemsSelectRefresh()
      },
      deep: true
    }
  },
  methods : {
    ...mapActions('documentflow', [
      'ADD_EDIT_DOCUMENT',
    ]),

    async addEditDocument() {

      if ( !this.entityData.name ) {
        this.nameError = true
        this.notify(this.$t('documentflow.document.name_error') , 'warning')
        return
      }
      if ( !this.entityData.type ) {
        this.typeError = true
        this.notify(this.$t('documentflow.document.type_error') , 'warning')
        return
      }
      if ( !this.entityData.code ) {
        this.codeError = true
        this.notify(this.$t('documentflow.document.code_error') , 'warning')
        return
      }

      if ( !this.entityData.date ) {
        this.dateError = true
        this.notify(this.$t('documentflow.document.date_error') , 'warning')
        return
      }

      if ( this.entityData.signators.length === 0 ) {
        this.signatorError = true
        this.notify(this.$t('documentflow.document.signator_error') , 'warning')
        return
      }

      if ( !this.entityData.file) {
        this.fileError = true
        this.notify(this.$t('documentflow.document.file_error') , 'warning')
        return
      }

      let result = await this.ADD_EDIT_DOCUMENT(this.entityData)
      if ( !result )  {
        this.ERROR()
        return
      }
      this.$emit('update' , {isNew : this.newEntity, entity : result })

      this.display = false
    },


    getUser(value) {
      let user = this.managersSelect.find(el => el.value === value)
      if ( user ) {
        return user
      }
      return {}
    },
    getUserName(value) {
      let user = this.managersSelect.find(el => el.value === value)
      if ( user ) {
        return user.text
      }
      return ''
    },
    removeSignator(value , isAproval) {
      this.updateTrigger++
      if ( !isAproval ) {
        this.entityData.signators = this.entityData.signators.filter(el => el.user_id !== value)
      } else {
        this.entityData.aprovals = this.entityData.aprovals.filter(el => el.user_id !== value)
      }

    },
    addSignator(value , isAproval = false) {
      this.signatorError = null

      let user = this.getUser(value)

      let data = {
        user_id : value
      }
      if ( user.positions_select && user.positions_select.length > 0 ) {
        data.position_id = user.positions_select[0].value
      }

      if ( isAproval) {
        this.entityData.aprovals.push(data)
      } else {
        this.entityData.signators.push(data)
      }
      this.newSignator = null
      this.updateTrigger++
    },

    deleteFile(original) {
      if ( !original )  {
        this.file = {}
        this.entityData.file = null
        this.entityData.file_name = null
      } else {
        this.originalFile = {}
        this.entityData.original_file = null
        this.entityData.original_file_name = null
      }

    },
    addFile(file ,original = false) {
      this.fileError = null

      if ( !original )  {
        this.file = file
        this.entityData.file = file.uuid
        this.entityData.file_name = file.name
      } else {
        this.originalFile = file
        this.entityData.original_file = file.uuid
        this.entityData.original_file_name = file.name
      }

      this.entityData = this.COPY(this.entityData)
    },

    handleStructureChange() {
      this.structureError = null
      this.entityData.type = null
      this.entityData.signators = []
      this.entityData.aprovals = []
    },
    handleTypeChange() {
      this.typeError = null
      let type = this.documentTypesSelect.find(el => el.value === this.entityData.type)
      if ( !this.entityData.type || !type ) {
        this.entityData.signators = []
        this.entityData.aprovals = []
        return
      }
      if ( this.documentTypesData && this.documentTypesData[this.entityData.type] ) {
        let typeDataAll = this.documentTypesData[this.entityData.type]
        if ( typeDataAll && typeDataAll.length > 0 ) {
          let typeData = typeDataAll.find(el => el.structure_entity_id === this.entityData.structure_entity_id )
          if ( typeData ) {
            this.entityData.signators = typeData.signators
            this.entityData.aprovals = typeData.aprovals
          }
        }
      }

    },
    handleItemsSelectRefresh() {
      if (Object.keys(this.itemsSelectData).length) {
        this.documentTypesSelect = this.itemsSelectData.document_types_select
        this.documentTypesData = this.itemsSelectData.document_types_data
        this.documentDepartmentsSelect = this.itemsSelectData.document_departments
        this.managersSelect = this.itemsSelectData.managers_select
        this.hrStructureEntitiesSelect = this.itemsSelectData.structure_entities_select
        this.lists = this.itemsSelectData.lists
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
    if (this.entity && this.entity.uuid > 0) {
      this.entityData = this.COPY(this.entity)
    }

    this.handleItemsSelectRefresh()




  }
}
</script>

<style scoped>

</style>