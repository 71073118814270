<template>
  <sub-page
      :title="$t('Documents')"
      v-model="$store.state.settings.pageData"
      :header-action="openNewDocument"
      :header-action-condition="items.length > 0"
      :header-action-text="$t('documentflow.document.add')"
      icon="mdi-file-document-multiple-outline"

      :no-content-condition="items.length === 0"
      :no-content-button-action="openNewDocument"
      no-content-button-text="documentflow.document.add"
      no-content-text="documentflow.document.no_content"
      fill-height

      :filters="filtersSelect"
  >
    <template #default>

      <ws-data-table
          class="mt-5"
          :items="itemsFiltered"
          :headers="headers"
          :search="$store.state.settings.filtersData.search"
          :row-action="($event) => openDocumentDialog($event)"
          :context-actions-select="documentActionsSelect"
          @contextAction="($event , item) => documentAction($event,item)"

      >

        <template #item.date="{item}">
          <h5 class="text-no-wrap">{{ MONTH_DAY_TIME(item.date , false) }}</h5>
        </template>

        <template #item.name="{item}">
          <h5 style="min-width: 300px" :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
        </template>

        <template #item.structure_entity_name="{item}">
          <h5 class="shortenText" >{{ item.structure_entity_name }}</h5>
        </template>

        <template #item.code="{item}">
          <h5 class="text-no-wrap" >{{ item.code }}</h5>
        </template>

        <template #item.is_signed="{item}">
          <div class="d-flex justify-center">
            <v-icon v-if="!item.is_declined" :color="item.is_signed ? wsACCENT : null">mdi-check-decagram</v-icon>
            <v-icon v-else :color="wsATTENTION" >mdi-alert</v-icon>
          </div>
        </template>

        <template #item.action="{item , hover}">
          <div style="width : 40px">
            <ft-select
                @input="documentAction($event , item)"
                :items="documentActionsSelect"
            >
              <v-btn v-if="hover" icon :color="wsACCENT">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </ft-select>
          </div>

        </template>

      </ws-data-table>
    </template>

    <template #dialog>

      <!-- Document Preview Dialog-->
      <document-dialog
          v-if="displayDocumentDialog"
          v-model="displayDocumentDialog"
          :document="selectedDocument"
      />

      <!-- Delete Document Dialog-->
      <ws-dialog
          v-if="displayDeleteDialog"
          v-model="displayDeleteDialog"
          :title="$t('Delete')"
          @delete="deleteDocument"
          @save="deleteDocument"
          :save-text="$t('Delete')"
      >
        <h4 class="wsDARKER font-weight-regular text-center mt-10">Ви впевнені що хочете видалити документ:</h4>
        <h5 class="wsACCENT text-center">{{ selectedDocument.name }}</h5>
      </ws-dialog>

      <!-- Document CRUD dialog-->
      <document-settings-dialog
          v-if="displayDialog"
          v-model="displayDialog"
          @update="addEditDocument"
          :entity="selectedDocument"
          :items-select-data="itemsSelectData"
      />




    </template>


  </sub-page>
</template>

<script>
import {mapActions} from "vuex";
import documentDialog from "@/components/pages/businessDashboard/businessDocumentflow/UI/documentDialog";
import documentSettingsDialog
  from "@/components/pages/businessDashboard/businessDocumentflow/UI/documentSettingsDialog.vue";
export default {
  name: "Tasks",
  components : {
    documentDialog,
    documentSettingsDialog
  },
  data() {
    return {
      items : [],
      itemsSelectData : {},

      displayDialog : false,
      displayDocumentDialog : false,
      displayDeleteDialog : false,
      selectedDocument : {},


    }
  },
  computed : {
    headers() {
      return [
        {  value : 'is_signed' , width : 10 , sortable : false },
        { text : this.$t('Date') , value : 'date' , width : 10 },
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('StructureEntity') , value : 'structure_entity_name' , width : 200 },
        { text : this.$t('Type') , value : 'type_name' , width : 60 },
        { text : this.$t('Code') , value : 'code' , width : 10 },
        { text : this.$t('Owner') , value : 'owner_name' , width : 10 },
        { value : 'public_key' , width : 10 , align : 'd-none' },
        { value : 'action' , width : 50 },
      ]
    },
    documentActionsSelect() {
      let items  = [
        { text : this.$t('Open') ,  value : 'view' },
        { text : this.$t('Download') , value : 'download' },
      ]

      let allowDelete = false

      if (this.IS_BUSINESS_OWNER) {
        allowDelete = true
      }

      if (allowDelete) {
        items.push({ text : this.$t('Delete') , value : 'delete' })
      }
      return items

    },

    itemsFiltered() {
      let items = this.COPY(this.items)

      if ( this.$store.state.settings.filtersData.structure_entity_id ) {
        items = items.filter(el => el.structure_entity_id === this.$store.state.settings.filtersData.structure_entity_id )
      }

      if ( this.$store.state.settings.filtersData.type ) {
        items = items.filter(el => el.type === this.$store.state.settings.filtersData.type )
      }

      if ( this.$store.state.settings.filtersData.owner_id ) {
        items = items.filter(el => el.owner_id === this.$store.state.settings.filtersData.owner_id )
      }

      if ( [true,false].includes( this.$store.state.settings.filtersData.is_signed ) ) {
        if ( this.$store.state.settings.filtersData.is_signed === true ) {
          items = items.filter(el => el.is_signed === true )
        } else {
          items = items.filter(el => !el.is_signed  )
        }
      }

      return items
    },

    filtersSelect(){
      return [
        { text : this.$t('StructureEntity')  , value : 'structure_entity_id' , type : 'select' , items : this.hrStructureEntitiesSelect },
        { text : this.$t('Type')  , value : 'type' , type : 'select' , items : this.documentTypesSelect },
        { text : this.$t('IsSigned')  , value : 'is_signed' , type : 'select' , items : [
            { text : this.$t('IsSigned') , value : true },
            { text : this.$t('NotSigned'), value : false }
        ]},
        { text : this.$t('Owner')  , value : 'owner_id' , type : 'select' , items : this.managersSelect },
      ]
    },
  },
  methods : {
    ...mapActions('documentflow', [
      'GET_DOCUMENTS',
      'ADD_EDIT_DOCUMENT',
      'DELETE_DOCUMENT',
    ]),
    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),

    checkParentDocument(code) {
      console.log('Check parent ajax' + code)
    },

    documentAction(action , item) {
      switch(action) {
        case 'view' : return this.openDocumentDialog(item)
        case 'download' : return this.downloadFile(item.file_data)
        case 'delete' : return this.openDelete(item)
      }
    },
    openDocumentDialog(event) {
      this.selectedDocument = event
      this.displayDocumentDialog = true
    },
    openDelete(event) {
      this.selectedDocument = event
      this.displayDeleteDialog = true
    },
    async deleteDocument() {
      let result = await this.DELETE_DOCUMENT({uuid : this.selectedDocument.uuid})
      if (!result) {
        this.notify(this.$t('NetworkError'))
      }
      this.items = this.items.filter(el => el.uuid !== this.selectedDocument.uuid)

      this.selectedDocuemnt = {}
      this.displayDeleteDialog = false
    },


    handleStructureChange() {
      this.structureError = null
      this.entityData.type = null
      this.entityData.signators = []
      this.entityData.aprovals = []
    },
    handleTypeChange() {
      this.typeError = null
      let type = this.documentTypesSelect.find(el => el.value === this.entityData.type)
      if ( !this.entityData.type || !type ) {
        this.entityData.signators = []
        this.entityData.aprovals = []
        return
      }
      if ( this.documentTypesData && this.documentTypesData[this.entityData.type] ) {
        let typeDataAll = this.documentTypesData[this.entityData.type]
        if ( typeDataAll && typeDataAll.length > 0 ) {
          let typeData = typeDataAll.find(el => el.structure_entity_id === this.entityData.structure_entity_id )
          if ( typeData ) {
            this.entityData.signators = typeData.signators
            this.entityData.aprovals = typeData.aprovals
          }
        }
      }

    },

    async downloadFile(item) {

      let result = await this.GET_PRIVATE_FILE(item.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },


    async addEditDocument(event) {
      if ( event.isNew) {
        this.items.unshift(event.entity)
      } else {
        let index = this.items.findIndex(el => el.uuid === event.entity.uuid)
        this.items[index] = this.COPY(event.entity)
        this.items = this.COPY(this.items)
      }
      this.displayDialog = false
    },
    openNewDocument() {
      this.nameError = null
      this.typeError = null
      this.codeError = null
      this.dateError = null
      this.signatorError = null
      this.fileError = null

      this.newSignator = null
      this.newEntity = true
      this.entityData = this.COPY( {
        signators : [],
        aprovals : []
      })
      this.displayDialog = true
    },
    openEditDocument(item) {
      this.nameError = null
      this.typeError = null
      this.codeError = null
      this.dateError = null
      this.signatorError = null
      this.fileError = null

      this.newEntity = false
      this.entityData = this.COPY(item)
      this.displayDialog = true
    },

    // technical

    async initPage() {
      let result = await this.GET_DOCUMENTS()
      if ( !result )  {
        this.ERROR()
      }
      result.items.reverse()
      this.items = result.items

      this.itemsSelectData = result
      delete this.itemsSelectData.items

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>